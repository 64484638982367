.panel {
  background-color: var(--color-white);
  border-radius: 24px;
  box-shadow: 0 8px 12px rgba(0, 27, 72, 0.04);
  height: 100%;
  min-width: 340px;
  overflow: hidden;
  width: var(--edit-panel-width);
}

.content {
  height: calc(100% - 50px - 32px);
  -ms-overflow-style: none;
  overflow-y: scroll;
  padding: 0 12px;
  scrollbar-width: none;
  margin-top: 32px;

  &::-webkit-scrollbar {
    display: none;
  }

  & > *:nth-last-child(1) {
    // padding-bottom in .content doesn't work in FF
    margin-bottom: 32px;
  }
}

.footer {
  border-top: 1px solid var(--color-gray);
  color: var(--color-light-gray);
  font-size: 14px;
  height: 50px;
  margin: 0 8px;
  padding: 16px 0;
  text-align: center;

  & > a {
    text-decoration: underline;
  }
}

.email {
  display: flex;
  font-size: 16px;
  padding: 16px 12px;
}

.editEmailButton {
  background-color: transparent;
  border-style: none;
  color: var(--color-primary);
  cursor: pointer;
  font-size: 16px;
  margin-left: auto;
  outline: none;

  &:hover {
    color: var(--color-primary-active);
  }
}
