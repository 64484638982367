@import 'input';

.input {
  @include inputStyles;
}

.inputTransparent {
  background-color: transparent;
}

.hasError {
  border-color: var(--color-danger) !important;

  &:focus,
  &:focus:hover {
    border-color: var(--color-danger);
    box-shadow: 0 0 0 2px rgba(var(--color-danger-rgb), 0.5);
  }

  &:hover:not(:disabled) {
    box-shadow: 0 0 0 2px rgba(var(--color-danger-rgb), 0.2);
  }
}
