.radio {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  margin-top: 12px;
}

.margin {
  height: 12px;
}

.input {
  appearance: none;
  margin: 0;
  outline: none;
  padding: 0;

  &:before {
    border: 1px solid var(--color-light-gray);
    border-radius: 18px;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 18px;
    margin-right: 12px;
    transition: border var(--transition-duration) ease;
    width: 18px;
  }

  &:checked:before {
    border: 6px solid var(--color-primary);
  }
}

.radio:hover .input:before {
  background-color: var(--color-gray);
}

.childrenTransition {
  overflow: hidden;
  padding-left: 30px;
  transition: all var(--transition-duration) ease;
}

.children {
  padding-top: 12px;
}
