.stTools {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all var(--transition-duration) ease;
}

.overlay {
  display: flex;
  flex-direction: column;
  position: absolute;
  transition: all var(--transition-duration) ease;
}

.popup {
  bottom: 0;
  height: max-content;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  transition: all var(--transition-duration) ease;
  width: max-content;
}

.triggerButton {
  bottom: 0;
  height: max-content;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  transition: all var(--transition-duration) ease;
  width: max-content;
}
