.previewPage {
  height: calc(100vh - var(--header-height));
  width: 100vw;
}

.previewContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-left: 32px;
  padding-right: 32px;
  width: fit-content;
}

.device {
  align-items: center;
  display: flex;
}

.restartButton {
  margin-left: 32px;
  width: 130px;
}

.restartText {
  align-items: center;
  display: flex;
  margin: auto;
  width: fit-content;

  &:before {
    content: 'refresh';
    display: block;
    font-family: var(--material-icon-font-family);
    font-feature-settings: 'liga';
    font-size: 24px;
    height: 24px;
    margin-right: 12px;
    width: 24px;
  }
}
