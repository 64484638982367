.margin {
  height: 140px;
}

.top {
  margin: auto;
  position: relative;
  width: 80px;
  z-index: 1;
}

.leftAndRight {
  display: flex;
  position: relative;
  top: -26px;
}

.left {
  margin-right: auto;
  width: 80px;
}

.right {
  margin-left: auto;
  width: 80px;
}

.bottom {
  margin: auto;
  position: relative;
  top: -48px;
  width: 80px;
  z-index: 1;
}
