.list {
  border-bottom: 1px solid var(--color-gray);
  display: flex;
  height: 44px;
  list-style: none;
  margin: 0;
  margin-bottom: 24px;
  margin-top: -24px;
  padding: 0;
}

.tabElement {
  margin-left: 32px;
}

.tab {
  appearance: none;
  background-color: transparent;
  border-style: none;
  color: rgba(var(--color-dark-rgb), 0.5);
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  height: 100%;
  outline: none;
  padding: 0;
  padding-bottom: 2px;
  position: relative;
  transition: color var(--transition-duration) ease;

  &:hover {
    color: rgba(var(--color-dark-rgb), 1);
  }

  &:after {
    background-color: rgba(var(--color-dark-rgb), 0);
    bottom: 0;
    content: '';
    display: block;
    height: 0;
    left: 0;
    position: absolute;
    right: 0;
    transition: all var(--transition-duration) ease;
    z-index: 1;
  }
}

.activeTab {
  color: rgba(var(--color-dark-rgb), 1);

  &:after {
    background-color: rgba(var(--color-dark-rgb), 1);
    height: 2px;
  }
}

.hasError {
  color: var(--color-danger);

  &:hover {
    color: rgba(var(--color-danger-rgb), 0.8);
  }
}

.hasError.activeTab:after {
  background-color: var(--color-danger);
}
