.row {
  display: flex;
  min-height: 76px;
  padding-bottom: 18px;

  &:nth-last-of-type(1) {
    height: auto;
  }

  & > * {
    margin-right: 12px;
    width: 100%;

    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
}

.noPadding {
  min-height: 76px - 18px;
  padding-bottom: 0;
}
