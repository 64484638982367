.st-tools {
  overflow-y: scroll;
  scrollbar-width: none;
}

.st-tools::-webkit-scrollbar {
  display: none;
}

.st-tools a {
  text-decoration: none !important;
}
