.gallery {
  height: calc(100vh - var(--header-height));
  width: 100%;
}

.templates {
  display: grid;
  grid-gap: 20px 20px;
  grid-template-columns: repeat(4, 1fr);
  margin: auto;
  max-width: 1024px;
  padding: 32px;
}

.template {
  width: 100%;
}

.deviceContainer {
  padding-top: calc(100% * 2.1);
  position: relative;
  width: 100%;
}

.screen {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.imageContainer {
  position: relative;
  width: 100%;
}

.selectButtonContainer {
  align-items: center;
  background-color: var(--color-modal-overlay);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.templateImage {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.templateName {
  color: var(--color-dark-gray);
  font-size: 14px;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.currentTemplate {
  align-items: center;
  color: var(--color-primary);
  display: flex;
  font-weight: 600;

  &:before {
    background-color: var(--color-primary);
    content: '';
    display: block;
    height: 20px;
    margin-right: 8px;
    mask-image: url('assets/dist.svg');
    min-width: 20px;
    width: 20px;
  }
}

.filterBy {
  align-items: center;
  color: var(--color-on-background);
  display: flex;
  margin: auto;
  margin-top: 12px;
  max-width: fit-content;
  padding-left: 32px;
  padding-right: 32px;
  white-space: nowrap;
  width: 100%;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-left: 12px;
  width: 100%;
}

.tag {
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 30px;
  margin: 8px;
  outline: none;
  padding-left: 12px;
  padding-right: 12px;
  transition: background-color var(--transition-duration) ease;

  &:hover {
    background-color: var(--color-lgray);
  }
}

.tagChecked {
  background-color: var(--color-white);
}
