@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url('ProximaNova-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 500;
  src: url('ProximaNova-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  src: url('ProximaNova-Semibold.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  src: url('ProximaNova-Bold.woff') format('woff');
}

@font-face {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  src: url('icomoon.eot?fa3kex');
  src: url('icomoon.eot?fa3kex#iefix') format('embedded-opentype'),
    url('icomoon.ttf?fa3kex') format('truetype'), url('icomoon.woff?fa3kex') format('woff'),
    url('icomoon.svg?fa3kex#icomoon') format('svg');
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icomoon' !important; /* stylelint-disable-line */
  font-smoothing: antialiased; /* stylelint-disable-line */
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

.icon-search:before {
  content: '\e924';
}
.icon-info-outline:before {
  content: '\e923';
}
.icon-help-circle:before {
  content: '\e904';
}
.icon-share:before {
  content: '\e909';
}
.icon-log-out:before {
  content: '\e922';
}
.icon-boxed:before {
  content: '\e90a';
}
.icon-arrow-down:before {
  content: '\e90b';
}
.icon-new-project:before {
  content: '\e90c';
}
.icon-mobile:before {
  content: '\e90d';
}
.icon-desktop:before {
  content: '\e90e';
}
.icon-info:before {
  content: '\e90f';
}
.icon-align-center-horizontal:before {
  content: '\e910';
}
.icon-align-left:before {
  content: '\e911';
}
.icon-align-right:before {
  content: '\e912';
}
.icon-full-width:before {
  content: '\e913';
}
.icon-text-icon-right:before {
  content: '\e914';
}
.icon-text-icon-left:before {
  content: '\e915';
}
.icon-text-only:before {
  content: '\e916';
}
.icon-allow:before {
  content: '\e917';
}
.icon-message:before {
  content: '\e918';
}
.icon-italic:before {
  content: '\e919';
}
.icon-bold:before {
  content: '\e91a';
}
.icon-text-center:before {
  content: '\e91b';
}
.icon-text-left:before {
  content: '\e91c';
}
.icon-text-right:before {
  content: '\e91d';
}
.icon-plus:before {
  content: '\e91e';
}
.icon-minus:before {
  content: '\e91f';
}
.icon-card-style:before {
  content: '\e920';
}
.icon-new-window:before {
  content: '\e921';
}
.icon-menu:before {
  content: '\e900';
}
.icon-desktop-solid:before {
  content: '\e908';
}
.icon-sidebar-bold:before {
  content: '\e907';
}
.icon-sidebar:before {
  content: '\e906';
}
.icon-mail:before {
  content: '\e901';
}
.icon-twitter:before {
  content: '\e902';
}
.icon-facebook:before {
  content: '\e903';
}
.icon-close:before {
  content: '\e905';
}
.icon-settings:before {
  content: '\e925';
}
.icon-copy:before {
  content: '\e926';
}
.icon-arrow-special-right-top:before {
  content: '\e92a';
}
.icon-arrow-special-right:before {
  content: '\e928';
}
.icon-arrow-special-right-bottom:before {
  content: '\e929';
}
.icon-arrow-special-left-bottom:before {
  content: '\e927';
}
.icon-arrow-special-bottom-left:before {
  content: '\e92b';
}
.icon-refresh:before {
  content: '\e92c';
}
.icon-sec-text-section:before {
  content: '\e92d';
}
.icon-logo-section:before {
  content: '\e92e';
}
.icon-hero-section:before {
  content: '\e92f';
}
.icon-call-text-section:before {
  content: '\e930';
}
.icon-filter:before {
  content: '\e98e';
}
