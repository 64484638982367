.customizerPage {
  display: flex;
}

.leftPart {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--header-height));
  padding-bottom: 20px;
  padding-left: 20px;
  padding-top: 20px;
}

.configPanel {
  height: 100%;
  position: relative;
  width: 340px;
}

.configPanelTransition {
  bottom: 0;
  position: absolute;
  top: 0;
  transition: all var(--transition-duration) ease;
}

.previewScroll {
  height: calc(100vh - var(--header-height));
  width: 100%;
}

.preview {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  padding-bottom: 32px;
  padding-left: 20px;
  padding-right: 20px;
}

.expertMode {
  margin: auto;
  margin-top: 12px;
}

.devices {
  align-items: center;
  display: flex;
}

.arrow {
  margin-left: 24px;
  margin-right: 24px;
  width: 64px;
}

.deviceName {
  color: rgba(var(--color-dark-rgb), 0.5);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 8px;
  padding: 0;
  text-align: center;
}
