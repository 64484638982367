@import '../input-form-field/input';

.colorField {
  min-width: 140px;
  position: relative;
  width: 100%;
}

.input {
  @include inputStyles;
  padding-left: 40px;
}

.inputTransparent {
  background-color: transparent;
}

.hasError {
  border-color: var(--color-danger) !important;
}

.colorPicker {
  border: 1px solid var(--color-gray);
  border-radius: 12px;
  height: 24px;
  left: 8px;
  overflow: hidden;
  position: absolute;
  top: 32px;
  width: 24px;

  &::before {
    background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
    background-size: 6px;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }
}

.colorPickerButton {
  cursor: pointer;
  height: 100%;
  position: relative;
  width: 100%;
}
