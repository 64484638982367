@import '../../../../../form-components/input-form-field/input';

.delayInput {
  @include inputStyles;
  margin-left: 4px;
  margin-right: 4px;

  width: 54px;
}

.delayInputHasError {
  border-color: var(--color-danger) !important;
}

.helperText {
  color: var(--color-dark);
}

.link {
  color: var(--color-primary);
}

.twoLinePadding {
  height: 18px;
}
