.device {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.screen {
  border-radius: 32px;
  position: absolute;
  top: 0;
  left: 1%;
  right: 1%;
  bottom: 0;
  overflow: hidden;
  background-color: #000;
}

.screenScale {
  border-radius: 16px;
}

.screenHover {
  backdrop-filter: blur(2px);
  background-color: transparent;
  opacity: 0;
  transition: opacity var(--transition-duration) ease;
}

.screenHover:hover {
  opacity: 1;
}

.frame {
  box-shadow: inset 0px 0px 0px 1px #989898,
  inset 0 0 0 2px #2D2D2D,
  inset 0 0 0 3px rgba(255, 255, 255, 0.25),
  inset 0 0 0 8px #000000;
  border-radius: 32px;
  position: absolute;
  top: 0;
  left: 1%;
  right: 1%;
  bottom: 0;
  pointer-events: none;
}

.frameScale {
  box-shadow: inset 0px 0px 0px 1px #989898,
  inset 0 0 0 1px #2D2D2D,
  inset 0 0 0 2px rgba(255, 255, 255, 0.25),
  inset 0 0 0 4px #000000;
  border-radius: 16px;
}

$buttonGradient: linear-gradient(90deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0) 50%), linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 6.52%, rgba(255, 255, 255, 0.1) 13.17%, rgba(255, 255, 255, 0.1) 89.24%, rgba(255, 255, 255, 0) 94.56%, rgba(255, 255, 255, 0.2) 100%), #2D2D2D;

.volume {
  position: absolute;
  left: 0;
  top: 23%;
  width: 1%;
  height: 7%;
  background: $buttonGradient;
}

.bottomVolume {
  top: 23% + 7% + 2%;
}

.power {
  position: absolute;
  right: 0;
  top: 23%;
  width: 1%;
  height: 12%;
  background: $buttonGradient;
}

.title {
  display: flex;
  align-items: center;
  background: #000E24;
  color: var(--color-white);
  font-size: 8px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 8px;
  height: 26px;
  user-select: none;
}

.icons {
  margin-left: auto;
}

.icon {
  margin-right: 3px;

  &:nth-last-of-type(1) {
    margin-right: 0;
  }
}

.content {
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 8px;
  top: 8px;
}

.contentScale {
  left: 4px;
  right: 4px;
  bottom: 4px;
  top: 4px;
}

.contentWithTitle {
  top: 8px + 26px;
}
