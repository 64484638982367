@import '../input-form-field/input';

.input {
  @include inputStyles;
  height: auto;
  padding-bottom: 8px;

  padding-top: 8px;
  resize: none;
}

.inputTransparent {
  background-color: transparent;
}

.hasError {
  border-color: var(--color-danger) !important;
}
