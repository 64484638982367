.list {
  display: flex;
  justify-content: space-between;
}

.button {
  align-items: center;
  color: var(--color-dark-gray);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  outline: none;
}

.imageContainer {
  align-items: center;
  background-color: var(--color-white);
  border-radius: 20px;
  display: flex;
  height: 88px;
  justify-content: center;
  margin-bottom: 4px;
  transition: background-color var(--transition-duration) ease;
  width: 88px;
}

.image {
  border: 1px solid var(--color-light-gray);
  border-radius: 8px;
  height: 56px;
  object-fit: cover;
  overflow: hidden;
  transition: border var(--transition-duration) ease,
    background-color var(--transition-duration) ease, box-shadow var(--transition-duration) ease;
  width: 56px;
}

//.button:focus .image,
//.button:focus:hover .image {
//  border-color: var(--color-primary);
//  box-shadow: 0 0 0 2px rgba(var(--color-primary-rgb), 0.5);
//}
//
//.button:hover:not(:disabled) .image {
//  box-shadow: 0 0 0 2px rgba(var(--color-primary-rgb), 0.2);
//}

.button:hover:not(:disabled) .imageContainer {
  background-color: var(--color-lgray);
}

.active {
  background-color: var(--color-sgray);
}
