.smsApplication {
  background-color: var(--color-gray-additional);
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.keyword {
  padding-bottom: 16px;
}

.row {
  display: flex;
  margin-left: 4px;
}

.secondRow {
  margin-left: 8%;
}

.key {
  align-items: center;
  background-color: var(--color-white);
  border-radius: 4px;
  color: var(--color-gray);
  display: flex;
  height: 40px;
  justify-content: center;
  margin-bottom: 4px;
  margin-right: 4px;
  width: calc(10% - 4px);

  &:nth-last-of-type(1) {
    margin-right: 0;
  }
}

.space {
  background-color: var(--color-white);
  border-radius: 4px;
  height: 40px;
  margin-right: 4px;
  width: 100%;
}

.wideKey {
  background-color: var(--color-white);
  border-radius: 4px;
  height: 40px;
  margin-right: 4px;
  width: calc(18% - 4px);
}

.extraWideKey {
  background-color: var(--color-white);
  border-radius: 4px;
  height: 40px;
  margin-right: 4px;
  width: calc(36% - 4px);
}

.backButton {
  font-family: var(--material-icon-font-family);
  font-feature-settings: 'liga';
  font-size: 24px;
  height: 24px;
  margin-right: 12px;
  outline: none;
  width: 24px;
}

.phone {
  align-items: center;
  border-radius: 8px;
  display: flex;
  margin: 4px;
  margin-bottom: 8px;
  margin-top: 8px;
  min-height: 40px;
  padding: 0 20px;
  width: calc(100% - 8px);
}

.content {
  background-color: var(--color-white);
  height: 100%;
  overflow: hidden;
  position: relative;
}

.message {
  align-items: center;
  background-color: var(--color-white);
  border-radius: 8px;
  display: flex;
  margin: 4px;
  margin-bottom: 16px;
  margin-top: 8px;
  min-height: 40px;
  padding: 0 20px;
  width: calc(100% - 8px);
}

.messageFrom {
  background-color: var(--color-gray-additional);
  border-bottom-right-radius: 32px;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  bottom: 16px;
  height: 120px;
  left: 16px;
  position: absolute;
  width: 80%;
}

.messageTo {
  background-color: var(--color-gray-additional);
  border-bottom-left-radius: 32px;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  bottom: 16px + 16px + 120px;
  height: 80px;
  position: absolute;
  right: 16px;
  width: 80%;
}
