.actionButtons {
  display: flex;
  margin-top: 32px;

  & > * {
    margin-right: 8px;
    width: fit-content !important;

    &:nth-child(1) {
      margin-left: auto;
    }

    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
}
