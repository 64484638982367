.template {
  display: flex;
}

.device {
  border: 1px solid var(--border-color);
  border-radius: 16px;
  box-sizing: border-box;
  height: 200px;
  margin-right: 20px;
  min-width: 100px;
  overflow: hidden;
  position: relative;
  width: 100px;
}

.cameraAndSpeaker {
  align-items: center;
  display: flex;
  height: 18px;
  margin-left: auto;
  margin-right: auto;
  width: min-content;
}

.camera {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  box-sizing: border-box;
  height: 4px;
  margin-right: 10px;
  width: 4px;
}

.speaker {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  box-sizing: border-box;
  height: 4px;
  width: 20px;
}

.screen {
  border-radius: 4px;
  bottom: 12px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  right: 4px;
  top: 18px;
}

.image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
}

.pickNewThemeButton {
  color: var(--color-primary);
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;

  &:hover {
    color: var(--color-primary-active);
  }
}

.featuresSection {
  margin-top: 24px;
}

.features {
  list-style: none;
  margin: 0;
  padding: 0;
}

.feature {
  align-items: center;
  display: flex;
  font-size: 16px;
  margin-bottom: 4px;

  &:before {
    content: 'done';
    font-family: var(--material-icon-font-family);
    margin-right: 10px;
  }

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
}

.description {
  font-size: 16px;
  margin-bottom: 24px;
}
