.list {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  margin-top: 24px;
  padding: 0;
  width: 100%;
}

.button {
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 12px;
  color: var(--color-dark);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 120px;
  padding: 16px;
  width: 120px;

  &:hover {
    border-color: var(--color-gray);
  }
}

.buttonImage {
  margin-bottom: 24px;
  width: 60%;
}
