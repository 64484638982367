@keyframes anim-modal-show {
  0% {
    opacity: 0;
    transform: translate(-50%, -20%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.modal {
  animation: anim-modal-show 0.4s cubic-bezier(0.12, 0.9, 0.3, 0.9);
  animation-iteration-count: 1;
  background: var(--color-white);
  border-radius: 16px;
  bottom: auto;
  box-shadow: 0 4px 16px rgba(0, 27, 72, 0.1);
  left: 50%;
  margin-right: -50%;
  outline: none;
  padding: 16px;
  padding-top: 8px;
  position: relative;
  right: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 0 50%;
  width: 400px;
}

.titleRow {
  align-items: center;
  display: flex;
  margin-bottom: 16px;
  margin-right: -8px;
}

.title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
}

.close {
  align-self: flex-start;
  margin-left: auto;
}

.content {
  color: var(--color-dark);
  font-size: 16px;
  line-height: 130%;
}

@keyframes anim-modal-overlay-show {
  0% {
    background-color: rgba(225, 225, 225, 0);
  }
  100% {
    background-color: var(--color-modal-overlay);
  }
}

.overlay {
  animation: anim-modal-overlay-show 0.4s cubic-bezier(0.12, 0.9, 0.3, 0.9);
  animation-iteration-count: 1;
  backdrop-filter: blur(2px);
  background-color: var(--color-modal-overlay);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}
