.content {
  display: flex;
}

.preview {
  margin-right: 16px;
  pointer-events: none;
  user-select: none;
}

.publishButton {
  margin-bottom: 24px;
  margin-top: 24px;
}
