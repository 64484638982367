:root {
  --color-dark: rgba(0, 14, 36, 0.87);
  --color-dark-rgb: 0, 14, 36;
  --color-light: #eff3f6;
  --color-light-hover: #e6eef4;
  --color-gray-rgb: 0, 27, 72;
  --color-gray: rgba(0, 27, 72, 0.12);
  --color-lgray: rgba(0, 27, 72, 0.05);
  --color-disable: rgba(0, 27, 72, 0.03);
  --color-dark-gray: rgba(0, 27, 72, 0.54);
  --color-light-gray: rgba(0, 27, 72, 0.31);
  --color-extra-light: rgba(0, 0, 0, 0.15);
  --color-overlay: rgba(0, 14, 36, 0.35);
  --color-lagray: rgba(0, 27, 72, 0.25);
  --color-agray: rgba(0, 27, 72, 0.18);
  --color-sgray: rgba(0, 27, 72, 0.08);
  --color-gray-additional: #e9e9e9;
  --color-primary: #2399f0;
  --color-primary-rgb: 35, 153, 240;
  --color-primary-active: #006cbf;
  --color-white: #ffffff;
  --color-danger: #e63655;
  --color-danger-active: #b11d37;
  --color-green: #09bb8e;
  --color-danger-rgb: 230, 54, 85;
  --color-modal-overlay: rgba(0, 27, 72, 0.5);
  --color-on-background: rgba(var(--color-dark-rgb), 0.5);

  --warning-cover-color: #ffe589;

  --font-family: 'Proxima Nova', sans-serif;
  --icon-font-family: 'icomoon' !important;
  --material-icon-font-family: 'Material Icons' !important;

  --transition-duration: 300ms;

  --edit-panel-width: 320px;
  --header-height: 5rem;
  --header-height-mobile: 4rem;
}
