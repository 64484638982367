.description {
  font-size: 16px;
  margin-bottom: 12px;
  margin-top: 12px;
}

.small {
  color: var(--color-dark-gray);
  font-size: 14px;
}
