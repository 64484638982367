:root {
  --border-color: #a2aebe;
}

.deviceContainer {
  position: relative;
}

@keyframes ScreenAnimation {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.screen {
  animation: ScreenAnimation 2s ease infinite;
  background: var(--color-light);
  background: linear-gradient(
    90deg,
    rgba(211, 213, 218, 1) 35%,
    rgba(235, 237, 241, 1) 47%,
    rgba(235, 237, 241, 1) 53%,
    rgba(211, 213, 218, 1) 65%
  );
  background-position: -100% 0;
  background-size: 200%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.screenBackground {
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.widget {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
