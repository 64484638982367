.debugWidgetContainer {
  display: flex;
  position: relative;
  transition: all 300ms ease;
  width: 100%;
}

.debugWidgetInteractive {
  background-color: transparent;
  border: 1px dashed transparent;
  bottom: -4px;
  cursor: pointer;
  left: -4px;
  position: absolute;
  right: -4px;
  top: -4px;
}

.debugWidgetInteractive:hover {
  background-color: rgba(35, 153, 240, 0.05);
  border-color: #2399f0;
}
