.section {
  &:after {
    background-color: var(--color-gray);
    content: '';
    display: block;
    height: 1px;
    margin-bottom: 24px;
    margin-top: 24px;
    width: 100%;
  }

  &:nth-last-of-type(1):after {
    display: none;
  }
}

.title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 0;
}
