$tooltip-color: #6f7e96;

.st-tolltip-basic {
  background-color: $tooltip-color !important;
  border-radius: 8px !important;
  color: var(--color-white) !important;
  white-space: nowrap;

  &.place-left {
    &:after {
      border-left-color: $tooltip-color !important;
      border-left-style: solid !important;
      border-left-width: 5px !important;
      transform: translateX(-2px);
    }
  }

  &.place-right {
    &:after {
      border-right-color: $tooltip-color !important;
      border-right-style: solid !important;
      border-right-width: 5px !important;
      transform: translateX(2px);
    }
  }

  &.place-top {
    &:after {
      border-top-color: $tooltip-color !important;
      border-top-style: solid !important;
      border-top-width: 5px !important;
      transform: translateY(-1px);
    }
  }
  &.place-bottom {
    &:after {
      border-bottom-color: $tooltip-color !important;
      border-bottom-style: solid !important;
      border-bottom-width: 5px !important;
      margin-bottom: -5px;
      transform: translateY(2px);
    }
  }
}
