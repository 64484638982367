.button {
  appearance: none;
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 8px;
  color: var(--color-white);
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  height: 38px;
  outline: none;
  padding: 0 0.6em;
  transition: border var(--transition-duration) ease, color var(--transition-duration) ease,
    box-shadow var(--transition-duration) ease, background-color var(--transition-duration) ease;
  width: 100%;

  &:disabled {
    cursor: default;
  }

  &:focus,
  &:focus:hover {
    border-color: var(--color-primary-active);
    box-shadow: 0 0 0 2px rgba(var(--color-primary-rgb), 0.5);
  }

  &:hover:not(:disabled) {
    background-color: var(--color-primary-active);
    box-shadow: 0 0 0 2px rgba(var(--color-primary-rgb), 0.2);
  }
}

.grayButton {
  background-color: var(--color-sgray);
  border: 1px solid transparent;
  color: var(--color-dark);

  &:disabled {
    background-color: var(--color-sgray);
    border: 1px solid transparent;
    opacity: 0.5;
  }

  &:focus,
  &:focus:hover {
    background-color: var(--color-sgray);
    border-color: var(--color-sgray);
    box-shadow: 0 0 0 2px rgba(var(--color-gray-rgb), 0.08);
  }

  &:hover:not(:disabled) {
    background-color: var(--color-gray);
    border-color: transparent;
    box-shadow: 0 0 0 2px rgba(var(--color-gray-rgb), 0.04);
    color: var(--color-dark);
  }
}

.redButton {
  background-color: var(--color-danger);
  border: 1px solid transparent;
  color: var(--color-white);

  &:focus,
  &:focus:hover {
    background-color: var(--color-danger);
    border-color: var(--color-danger-active);
    box-shadow: 0 0 0 2px rgba(var(--color-danger-rgb), 0.5);
  }

  &:hover:not(:disabled) {
    background-color: var(--color-danger-active);
    border-color: transparent;
    box-shadow: 0 0 0 2px rgba(var(--color-danger-rgb), 0.2);
    color: var(--color-white);
  }
}

.transparentButton {
  background-color: transparent;
  border: 1px solid var(--color-light-gray);
  color: rgba(var(--color-dark-rgb), 0.5);
  font-weight: 400;

  &:focus,
  &:focus:hover {
    background-color: transparent;
    border-color: var(--color-primary);
  }

  &:hover:not(:disabled) {
    background-color: transparent;
    color: var(--color-dark);
  }
}

.transparentPrimaryButton {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--color-primary);
  font-weight: 600;

  &:focus,
  &:focus:hover {
    background-color: transparent;
    border-color: var(--color-primary);
  }

  &:hover:not(:disabled) {
    background-color: transparent;
    color: var(--color-primary-active);
  }
}
