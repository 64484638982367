.formField {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  color: var(--color-dark);
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.85em;
  transition: color 300ms ease;
  white-space: nowrap;
}

.disabledLabel {
  color: rgba(var(--color-dark-rgb), 0.5);
}

.labelTransparent {
  color: rgba(var(--color-dark-rgb), 0.5);
}

.bottomTextContainer {
  position: relative;
}

.bottomText {
  font-size: 14px;
  font-weight: 400;
  margin-top: 2px;
  opacity: 0;
  position: absolute;
  transform: translateY(-10px);
  transition: all var(--transition-duration) ease;
}

.bottomTextVisible {
  opacity: 1;
  transform: translateY(0px);
}

.error {
  color: var(--color-danger);
}

.helperText {
  color: var(--color-light-gray);
}
