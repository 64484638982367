.sectionTitle {
  color: rgba(var(--color-dark-rgb), 0.5);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 12px;
  margin-top: 32px;
  padding: 0;
}

.addWidget {
  margin-top: 12px;
}
