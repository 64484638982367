.content {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.image {
  height: 122px;
  width: 150px;
}

.title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 16px;
}

.description {
  max-width: 400px;
  text-align: center;
}

.link {
  color: var(--color-primary);
  font-size: 16px;
  margin-bottom: 32px;
  text-decoration: underline;

  &:hover {
    color: var(--color-primary-active);
  }
}

.button {
  max-width: 200px;
  width: 100%;
}
