.controls {
  display: flex;
  margin-bottom: 32px;
  margin-top: 16px;
  max-width: 100%;
}

.form {
  display: flex;
}

.deviceSelect {
  margin-right: 16px;
  min-width: 180px;
  width: 180px;
}

.urlInput {
  margin-right: 16px;
  min-width: 180px;
  width: 400px;
}

.applyButton {
  margin-top: auto;
  width: 100px;
}
