.switch {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  user-select: none;
}

.transparentSwitch {
  color: rgba(var(--color-dark-rgb), 0.5);
}

.checkbox {
  appearance: none;
  background-color: var(--color-sgray);
  border-radius: 26px;
  cursor: pointer;
  height: 26px;
  margin-right: 8px;
  outline: none;
  position: relative;
  transition: background-color var(--transition-duration) ease;
  width: 50px;

  &:before {
    background-color: var(--color-white);
    border-radius: 22px;
    content: '';
    height: 22px;
    left: 2px;
    position: absolute;
    top: 2px;
    transition: left var(--transition-duration) ease;
    width: 22px;
  }

  &:checked {
    background-color: var(--color-primary);
  }

  &:checked:before {
    left: 50px - 22px - 2px;
  }
}

.transparentCheckbox {
  background-color: transparent;
  border: 1px solid var(--color-light-gray);

  &:before {
    border: 1px solid var(--color-light-gray);
    height: 20px;
    left: 1px;
    top: 1px;
    width: 20px;
  }

  &:checked {
    background-color: var(--color-gray);
  }

  &:checked:before {
    left: 50px - 22px - 3px;
  }
}

.sizeMini.transparentCheckbox {
  height: 20px;
  width: 38px;

  &:before {
    height: 14px;
    width: 14px;
  }

  &:checked:before {
    left: 38px - 14px - 5px;
  }
}
