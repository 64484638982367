.button {
  background-color: transparent;
  border-style: none;
  color: inherit;
  cursor: pointer;
  font-family: var(--material-icon-font-family);
  font-feature-settings: 'liga';
  font-size: 24px;
  height: 40px;
  opacity: 0.4;
  outline: none;
  padding: 0;
  transition: opacity var(--transition-duration) ease;
  width: 40px;

  &:hover {
    opacity: 1;
  }
}
