.modal {
  display: flex;
  padding: 12px;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  margin-right: 64px;
}

.title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 16px;
  margin-top: 0;
  line-height: 1.2;
}

.form {
  margin-top: auto;
}

.rightColumn {
  display: flex;
  flex-direction: column;
}

.image {
  height: 324px;
  margin-bottom: 16px;
  width: 248px;
}

.button {
  margin-bottom: 18px;
  margin-top: auto;
}
