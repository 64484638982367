@import '../../../styles/media';

:root {
  --border-color: var(--color-light-gray);
}

.header {
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  height: var(--header-height);
  justify-content: space-between;
  width: 100%;
}

.logo {
  margin: 0 2rem;
  max-width: 180px;
}

.nav {
  height: 100%;
}

.tabs {
  display: flex;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.tab {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 16px;
  position: relative;
}

.link {
  align-items: center;
  color: rgba(var(--color-dark-rgb), 0.5);
  cursor: pointer;
  display: flex;
  font-weight: 600;
  height: 100%;
  transition: color var(--transition-duration) ease;
  white-space: nowrap;

  &:hover {
    color: rgba(var(--color-dark-rgb), 1);
  }

  &::after {
    background-color: var(--color-dark);
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    opacity: 0;
    position: absolute;
    transform: translateY(100%);
    transition: all var(--transition-duration) ease;
    width: 100%;
  }
}

.disabledLink {
  color: rgba(var(--color-dark-rgb), 0.2) !important;
  cursor: default;

  &:hover {
    color: rgba(var(--color-dark-rgb), 0.2) !important;
  }
}

.activeLink {
  color: rgba(var(--color-dark-rgb), 1);

  &::after {
    opacity: 1;
    transform: translateY(0);
  }
}

.rightPart {
  align-items: center;
  display: flex;
  margin: 0 2rem;
}

.unpublishedTooltip {
  font-size: 12px;
}

.revertButton {
  color: var(--color-primary);
  cursor: pointer;
  font-size: 12px;
  margin-left: 18px;
  outline: none;

  &:hover {
    color: var(--color-primary-active);
  }
}

.publishButton {
  appearance: none;

  background-color: var(--color-white);
  border-radius: 34px;
  border-style: none;
  box-shadow: 0 8px 12px rgba(0, 27, 72, 0.04);
  color: var(--color-dark-gray);
  cursor: pointer;
  font-size: 1rem;
  height: 34px;
  margin-left: 15px;
  outline: none;
  padding: 0 1em;
  text-decoration: none;
  transition: border var(--transition-duration) ease, color var(--transition-duration) ease,
    background-color var(--transition-duration) ease;
  white-space: nowrap;

  &:not(:disabled):hover {
    background-color: var(--color-white);
    border-color: var(--color-white);
    color: var(--color-primary);
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }
}
