@mixin inputStyles {
  border: 1px solid var(--color-light-gray);
  border-radius: 8px;
  color: var(--color-dark);
  font-family: var(--font-family);
  font-size: 16px;
  height: 38px;
  outline: none;
  padding: 0 0.6em;
  transition: border var(--transition-duration) ease,
    background-color var(--transition-duration) ease, box-shadow var(--transition-duration) ease;
  width: 100%;

  &::placeholder {
    color: var(--color-light-gray);
  }

  &:disabled {
    background-color: var(--color-disable);
    border-color: var(--color-light-gray);
    color: var(--color-light-gray);
  }

  &:focus,
  &:focus:hover {
    border-color: var(--color-primary);
    box-shadow: 0 0 0 2px rgba(var(--color-primary-rgb), 0.5);
  }

  &:hover:not(:disabled) {
    box-shadow: 0 0 0 2px rgba(var(--color-primary-rgb), 0.2);
  }
}
